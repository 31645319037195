<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">报价基本信息</el-col>
    </el-row>
    <el-form ref="Form" :model="supplierform" :rules="rules" :label-width="screenWidth>600?'120px':'68px'">
      <el-row :span="24">
        <el-col :span="screenWidth<600?24:8">
          <el-form-item label="报价单号" prop="offerCode">
            <el-input v-model="supplierform.offerCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="screenWidth<600?12:8">
          <el-form-item label="供应商" prop="vendorName">
            <el-input v-model="supplierform.vendorName" :placeholder="$t('page.inputPlaceholder')" disabled />
            <!--
            <el-select v-model="supplierform.vendorName" value-key="id" clearable filterable :placeholder="$t('page.selectPlaceholder')" :disabled="fordisabled">
              <el-option
                v-for="item in vendorOptions"
                :key="item.id"
                :label="item.vendorName"
                :value="item.id"
              />
            </el-select> -->
          </el-form-item>
        </el-col>

        <el-col :span="screenWidth<600?12:8">
          <el-form-item label="币种" prop="currencyType">
            <el-select
              v-model="supplierform.currencyType"
              :placeholder="$t('page.selectPlaceholder')"
              :disabled="fordisabled"
            >
              <!-- <el-option label="美元" value="1" />
              <el-option label="人民币" value="2" /> -->
              <el-option v-for="item in _getAllCommodityDict('CURRENCY_TYPE')" :key="item.val" :label="item.label" :value="item.val" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="screenWidth<600?12:8">
          <el-form-item label="状态" prop="offerStatus">
            <el-select v-model="supplierform.offerStatus" :placeholder="$t('page.selectPlaceholder')" disabled>
              <el-option
                v-for="item in _getAllCommodityDict('VENDOR_OFFER_STATUS')"
                :key="item.val"
                :label="item.label"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="screenWidth<600?12:8">
          <el-form-item label="报价人" prop="offerUser">
            <el-input v-model="supplierform.createByName" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="screenWidth<600?24:8">
          <el-form-item label="报价时间" prop="createTime">
            <el-date-picker
              v-model="supplierform.createTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="请选择"
              default-value
              :disabled="fordisabled"
            />
          </el-form-item>
        </el-col>
        <el-col :span="screenWidth<600?24:10">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="supplierform.remark" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="screenWidth<600?24:10">
          <el-form-item label="报价附件">
            <el-button v-for="(item,index) in supplierform.lfoafilelist" :key="index" type="text" @click="download(item)">{{ item.name }}</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item v-if="supplierform.offerStatus === '1'" ref="uploadRef" label="补充附件">
            <el-upload
              ref="uploadA"
              :headers="uploadHeaders"
              action=""
              class="upload-demo"
              :file-list="supplierform.replenishFilelist"
              :limit="10"
              show-file-list
              multiple
              :http-request="handleUploadFile"
              :before-upload="beforeUpload"
              :on-exceed="handleExceed"
              :on-success="replenishhandleSuccess"
            >
              <el-button size="small" type="primary" :loading="uploadLoading">点击上传</el-button>
              <div slot="file" slot-scope="{file}">
                <el-button type="text" :loading="uploadLoading" @click="downloadReplenish(file)">
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                  >
                    <div>{{ file.name }}</div>
                    <div slot="reference" style="width:120px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;">{{ file.name }}</div>
                  </el-popover>
                </el-button>
                <el-button v-permission="'delete'" style="color: red" type="text" :loading="uploadLoading" @click="handleRemove(file)">删除</el-button>
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">报价明细</span>

      </el-col>
    </el-row>
    <el-table
      ref="uploadTableDataRef"
      class="mb-3"
      :data="outboundDetailTable"
      :header-cell-style="{background:'#fafafa'}"
      max-height="600px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="50" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="productDevelopCode" label="产品开发编号" width="140" align="center" />

      <el-table-column prop="imgUrl" align="center" label="图片" width="160">
        <template slot-scope="scope">
          <img :src="scope.row.imgUrl||scope.row.imageUrl" alt="暂无图片" style="width: 50px;height: 50px">
        </template>
      </el-table-column>
      <el-table-column prop="style" label="规格型号" width="260" align="center">
        <template slot-scope="scope">
          {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
        </template>
      </el-table-column>
      <!--       <el-table-column prop="excludingTaxPrice" label="不含税单价" width="90" align="center" />
      <el-table-column prop="purchasePrice" label="含税单价" width="90" align="center" /> -->
      <el-table-column prop="offerPrice" label="单价" width="150" align="center" />
      <el-table-column prop="isExcludingTax" label="是否含税" width="120" align="center" sortable>
        <template slot-scope="scope">
          <p v-if="scope.row.isExcludingTax == 0">是</p>
          <p v-if="scope.row.isExcludingTax == 1">否</p>
        </template>
      </el-table-column>
      <el-table-column prop="effectiveStartDate" label="生效开始日期" width="150" align="center" />
      <el-table-column prop="effectiveEndDate" label="生效结束日期" width="150" align="center" />
      <el-table-column prop="isFlock" label="大底是否植绒" width="140" align="center" />
      <el-table-column prop="packMethod" label="包装方式" width="140" align="center" />

    </el-table>
  </div>
</template>
<script>
import { uploadByPreUrlApi, getVendorOfferId, getFileDownloadUrlByFileIds, saveAttachmentApi, deleteAttachmentApi } from '@/api/scm-api'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import axios from 'axios'
export default {
  mixins: [commodityInfoDict],
  data() {
    return {

      screenWidth: document.body.clientWidth,
      loading: false,
      editVisible: false,
      dialogVisible: false,
      fordisabled: false, // 禁用文本框
      supplierform: {
        purchaseStatus: '0',
        createTime: '',
        totalPurchaseAmount: '',
        pairs: '',
        purchasePlanCode: '',
        lfoafilelist: [],
        replenishFilelist: []
      },

      editForm: {},
      sidvoList: [],
      ids: [],
      vendorOptions: [],
      employeeOptions: [],
      styleOptions: [],
      editRow: {},
      showRow: false, // 切换显示input
      releaseTaskDialog: false, // 释放任务
      outboundDetailTable: [],
      Loadingsave: false,
      Loadingcommit: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      row: '',
      rules: {
        style: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        category1Code: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        turnover: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        ltDay: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        warehouseProcessDay: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        transportDay: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        platformShelfDay: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        safetyInventory: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }]
      },
      tabClickIndex: '',
      tabClickLabel: '',
      vendorId: '',
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      OldReplenishList: [],
      NewReplenishList: [],
      uploadCount: 0,
      uploadLoading: false
    }
  },
  computed: {
    isTransported() {
      return this.supplierform.offerStatus === '草稿' || this.supplierform.offerStatus === '审批中'
    },
    user() {
      return this.$store.getters.authorities
    }
  },
  watch: {
  },

  mounted() {
    if (this.$route.query && this.$route.query.disabled || this.$route.query.venderId) {
      this.fordisabled = true
    }
    // this._queryVendorList()
    // this._employeeList()
    this._getVendorOfferId()
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  methods: {

    // 供应商
    // async _queryVendorList() {
    //   const { datas } = await queryVendorList()
    //   this.vendorOptions = datas
    //   this.supplierform.vendorName = this.$route.query.vendorId
    // },
    async download({ id }) {
      console.log(id)
      const { datas } = await getFileDownloadUrlByFileIds([id])
      datas.map(item => window.open(item, '_blank'))
    },
    async downloadReplenish(file) {
      try {
        this.uploadLoading = true
        const { fileId } = file
        const { datas } = await getFileDownloadUrlByFileIds([fileId])
        datas.map(item => window.open(item, '_blank'))
        this.uploadLoading = false
      } finally {
        this.uploadLoading = false
      }
    },

    // 修改查询
    async _getVendorOfferId(query) {
      const { id } = this.$route.query
      if (id) {
        const { datas, datas: { currencyType, offerStatus, vendorName }} = await getVendorOfferId(id)
        this.supplierform = datas
        Object.assign(this.supplierform, datas, { vendorName, currencyType: currencyType + '', offerStatus: String(offerStatus) })
        // this.supplierform.vendorName = datas.vendorName
        // this.supplierform.currencyType = datas.currencyType + ''
        // this.supplierform.offerStatus = String(datas.offerStatus)
        if (datas.fileVOList) {
          this.supplierform.lfoafilelist = datas.fileVOList.filter(item => item.attachmentTypeDict === '1').map(item => {
            return { name: item.fileName, id: item.fileId }
          })
        }
        if (datas.replenishFileList) {
          this.supplierform.replenishFilelist = datas.replenishFileList.map(item => {
            return { url: item.fileKey, name: item.fileName, id: item.id, fileId: item.fileId }
          })
          this.OldReplenishList = [...this.supplierform.replenishFilelist]
        }
        sessionStorage.setItem('indatas', JSON.stringify(datas.details))
        this.outboundDetailTable = datas.details
      }
    },

    // 补充附件
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 50
      if (!isLt2M) {
        this.$message.error('文件最大不超过50MB')
        return false
      } else {
        return true
      }
    },
    async handleUploadFile(options) {
      this.uploadLoading = true
      const { name: fileName, size: kbSize } = options.file
      const params = {
        fileName,
        kbSize: Math.floor(kbSize / 1024),
        fileType: fileName.substring(fileName.lastIndexOf('.') + 1),
        configCode: 'vendorOfferAttachment'
      }
      const { datas } = await uploadByPreUrlApi(params)
      const {
        originName: name,
        objectName: key,
        policy,
        accessid: OSSAccessKeyId,
        callback,
        signature,
        host: url
      } = datas
      const form = Object.assign(
        {},
        { name, key, policy, OSSAccessKeyId, callback, signature },
        { file: options?.file }
      )
      const formData = new FormData()
      Object.keys(form).forEach((key) => formData.set(`${key}`, form[key]))
      const { data: { datas: response }} = await axios({
        method: 'post',
        url,
        data: formData
      })
      response && options.onSuccess(response) // 成功之后取值
    },
    async replenishhandleSuccess(response, file, fileList) {
      try {
        this.uploadLoading = true
        if (response) {
          this.uploadCount = this.uploadCount + 1
          this.NewReplenishList.push(response.objectName)
          if (this.uploadCount === fileList.length - this.OldReplenishList.length) {
            const params = {}
            Object.assign(params, { replenishFileKeyList: this.NewReplenishList, offerCode: this.supplierform.offerCode })
            const { datas } = await saveAttachmentApi(params)
            datas.map(item => this.OldReplenishList.push(
              { url: item.fileKey,
                name: item.fileName, id: item.id, fileId: item.fileId }
            ))
            this.supplierform.replenishFilelist = this.OldReplenishList
            this.uploadLoading = false
            this.uploadCount = 0
            this.NewReplenishList = []
          }
        }
      } catch {
        this.uploadLoading = false
      }
    },
    handleExceed() {
      this.$message.warning('当前限制选择 10 个文件')
    },
    async handleRemove(file) {
      try {
        this.uploadLoading = true
        this.$confirm('确认后将删除文件，是否确认', this.$t('page.Prompt'), {
          confirmButtonText: this.$t('title.confirm'),
          cancelButtonText: this.$t('title.cancel'),
          type: 'warning'
        }).then(async() => {
          const idList = []
          idList.push(file.id)
          const index = this.supplierform.replenishFilelist.findIndex((item) => item.id === file.id)
          this.supplierform.replenishFilelist.splice(index, 1)
          this.$refs.uploadA.handleRemove(file)
          const params = {}
          Object.assign(params, { idList, offerCode: this.supplierform.offerCode })
          await deleteAttachmentApi(params)
          this.$notify({
            message: '删除成功',
            type: 'success'
          })
          this.uploadLoading = false
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      } finally {
        this.uploadLoading = false
      }
    }

  }
}
</script>
<style lang="scss" scoped>
@media (max-width: 600px) {
  /deep/.el-input__inner {
    padding: 0px 0 0 8px !important;
    font-size: 13px !important;
  }
  /deep/.el-date-editor .el-input__inner {
    padding-left: 25px !important;
  }
}
  /deep/.el-upload-list, .el-upload-list--text {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }
  /deep/.el-upload-list__item {
    width: 200px;
    margin-top: 10px;
    transition: none !important;
  }
</style>

